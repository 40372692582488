<template>
  <div class="history payment_list">
    <template v-for="(item, index) in paymentList.content">
      <article :key="'item' + index" class="history__item payment_item">
        <section class="item-header">
          <div class="item-header__left">
            <span>#{{ paymentList.content.length - index }}.</span>
            <b
              >{{ $t("mypage.orderDate") }}&nbsp;
              {{ item.orderDate | moment("YYYY-MM-DD HH:mm:ss") }}</b
            >
          </div>
          <button
            class="item-header__right"
            v-b-toggle="'item-' + index"
            variant="link"
            :href="'#item-' + index"
            @click.prevent
          >
            <span>{{ $t("mypage.details") }}</span>
            <img src="/img/threespace/icon/chevron.svg" />
          </button>
        </section>
        <section class="item-content">
          <div class="img-box">
            <template
              v-if="isProductFileTypeImage(item.product.displayCardFileType)"
            >
              <b-img
                :src="replaceDisplayCardUrl(item.product)"
                class="cursor-pointer"
                @click="showProductDetail(item.idxProduct)"
              ></b-img>
            </template>
            <template v-else>
              <video
                :poster="replaceDisplayPosterUrl(item.product)"
                :src="replaceDisplayCardUrl(item.product)"
                type="video/webm"
                height="150px"
                muted
                loop
                autoplay
                playsinline
                class="cursor-pointer"
                @click="showProductDetail(item.idxProduct)"
              ></video>
            </template>
          </div>
          <section class="info-box">
            <!-- 작품명 -->
            <div>
              <p>{{ $t("mypage.artwork") }}</p>
              <p>
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  {{ item.product.productName || item.product.productNameEn }}
                </template>
                <template v-else>
                  {{ item.product.productNameEn || item.product.productName }}
                </template>
              </p>
            </div>
            <!-- 결제금액 -->
            <div>
              <p>{{ $t("mypage.paymentAmount") }}</p>
              <p>
                <template
                  v-if="
                    item.payType === 'VBANK' ||
                    item.payType === 'CARD' ||
                    item.payType === 'TRANSFER'
                  "
                >
                  {{ item.amt | currency }} {{ item.currency }}
                </template>
                <template v-else>
                  {{ item.amountCoin | currencyCoin(item.currency) }}
                  {{ item.currency }} ({{ item.amt | currency }} KRW)
                </template>
              </p>
            </div>
            <!-- 결제상태 -->
            <div>
              <p>{{ $t("mypage.status") }}</p>
              <div>
                <p>
                  <template v-if="item.payType === 'VBANK'">
                    {{ $t("mypage.payTypeVbank") }}
                  </template>
                  <template v-else-if="item.payType === 'CARD'">
                    {{ $t("mypage.payTypeCard") }}
                  </template>
                  <template v-else-if="item.payType === 'TRANSFER'">
                    {{ $t("mypage.payTypeTransfer") }}
                  </template>
                  <template v-else-if="item.payType === 'KLAY'">
                    {{ $t("mypage.payTypeKlay") }}
                  </template>
                  <template v-else-if="item.payType === 'MATIC'">
                    {{ $t("mypage.payTypeMATIC") }}
                  </template>
                  <template v-else-if="item.payType === 'SSX'">
                    {{ $t("mypage.payTypeSSX") }}
                  </template>
                  <template v-else-if="item.payType === 'ETH'">
                    {{ $t("mypage.payTypeEth") }}
                  </template>
                </p>
                <div
                  :class="{
                    'payment-status': true,
                    complete: item.stateCd === '0',
                    cancel:
                      item.stateCd === '1' ||
                      item.stateCd === '2' ||
                      item.stateCd === '3' ||
                      item.stateCd === '4',
                    pending: item.stateCd === '9',
                    idle:
                      item.stateCd !== '0' &&
                      item.stateCd !== '1' &&
                      item.stateCd !== '2' &&
                      item.stateCd !== '3' &&
                      item.stateCd !== '4' &&
                      item.stateCd !== '9',
                  }"
                >
                  <img
                    :src="`/img/threespace/icon/complete.svg`"
                    v-if="item.stateCd === '0'"
                  />
                  <img
                    :src="`/img/threespace/icon/cancel.svg`"
                    v-else-if="
                      item.stateCd === '1' ||
                      item.stateCd === '2' ||
                      item.stateCd === '3' ||
                      item.stateCd === '4'
                    "
                  />
                  <img
                    :src="`/img/threespace/icon/pending.svg`"
                    v-else-if="item.stateCd === '9'"
                  />
                  <p>{{ replacePaymentStateName(item.stateCd) }}</p>
                </div>
              </div>
              <div v-if="item.stateCd === '9'" class="wiretransfer-info">
                <span>
                  {{ $t("payment.wireTransfer.depositInfo") }}
                </span>
                <span>
                  {{ $t("payment.wireTransfer.depositor") }} : {{ item.buyerName }}
                </span>
                <span v-html="
                  $t('payment.wireTransfer.briefDepositDescription1', {
                    year: getExpYear(item.vbankExpDate),
                    month: getExpMonth(item.vbankExpDate),
                    day: getExpDay(item.vbankExpDate),
                    time: getExpTime(item.vbankExpTime)
                  })
                "></span>
              </div>
            </div>
          </section>
        </section>
        <b-collapse :id="'item-' + index">
          <section class="item-details">
            <!-- 작품정보 ========================================= -->
            <article class="content">
              <p class="title">About</p>
              <section class="info-box">
                <div>
                  <!-- 작품명 -->
                  <p>{{ $t("mypage.artwork") }}</p>
                  <p>
                    <template
                      v-if="
                        activeLanguage !== undefined && activeLanguage === 'ko'
                      "
                    >
                      {{
                        item.product.productName || item.product.productNameEn
                      }}
                    </template>
                    <template v-else>
                      {{
                        item.product.productNameEn || item.product.productName
                      }}
                    </template>
                  </p>
                </div>
                <div>
                  <!-- 작가명 -->
                  <p>{{ $t("mypage.artist") }}</p>
                  <p>
                    <template
                      v-if="
                        activeLanguage !== undefined && activeLanguage === 'ko'
                      "
                    >
                      {{ item.product.artistName || item.product.artistNameEn }}
                    </template>
                    <template v-else>
                      {{ item.product.artistNameEn || item.product.artistName }}
                    </template>
                    (@{{ item.product.artistNickName }})
                  </p>
                </div>
                <div>
                  <!-- 작품 NFT 고유번호 -->
                  <p>{{ $t("mypage.nftTokenId") }}</p>
                  <p>
                    <!-- <template
                      v-if="
                        item.product.nftType !== undefined &&
                        item.product.nftType !== null
                      "
                    >
                      <template v-if="item.product.nftType === 'ETHEREUM'">
                        <CImg
                          height="18"
                          src="/img/threespace/company/ethereum-eth-logo.svg"
                        />
                      </template>
                      <template v-else-if="item.product.nftType === 'POLYGON'">
                        <CImg
                          height="16"
                          src="/img/threespace/company/polygon-matic-logo.png"
                        />
                      </template>
                      <template
                        v-else-if="
                          item.product.nftType === 'KLAYTN' ||
                          item.product.nftType === 'KLIP'
                        "
                      >
                        <CImg
                          height="16"
                          src="/img/threespace/company/kaia-black.png"
                        />
                      </template>
                    </template> -->
                    #{{ item.product.tokenId || "(정보없음)" }}
                  </p>
                </div>
              </section>
            </article>
            <!-- 구매자 정보 ========================================= -->
            <article class="content">
              <p class="title">Buyer info</p>
              <section class="info-box">
                <div>
                  <!-- 사용자 이름 -->
                  <p>{{ $t("mypage.username") }}</p>
                  <p>{{ item.buyerName || "-" }}</p>
                </div>
                <div>
                  <!-- 연락처 -->
                  <p>{{ $t("mypage.mobile") }}</p>
                  <p>{{ item.buyerTel || "-" }}</p>
                </div>
                <div>
                  <!-- 계정 이메일 -->
                  <p>{{ $t("mypage.email") }}</p>
                  <p>{{ item.buyerEmail || "-" }}</p>
                </div>
                <div>
                  <!-- nft 수신 주소 -->
                  <p>
                    {{ $t("mypage.transactionHistory") }}
                    <font-awesome-icon
                      icon="exclamation-circle"
                      :id="'nft-info-' + index"
                      class="ml-1"
                    />
                    <b-tooltip :target="'nft-info-' + index" triggers="hover">
                      {{ $t("mypage.txHistory") }}
                    </b-tooltip>
                  </p>
                  <div class="address-box">
                    <p>
                      <template
                        v-if="
                          item.product.nftType !== undefined &&
                          item.product.nftType !== null
                        "
                      >
                        <template v-if="item.product.nftType === 'ETHEREUM'">
                          {{
                            memberInfo.ethereumAddr
                              ? memberInfo.ethereumAddr.slice(0, 10) +
                                "..." +
                                memberInfo.ethereumAddr.slice(-5)
                              : "(정보없음)"
                          }}
                        </template>
                        <template
                          v-else-if="
                            item.product.nftType === 'KLAYTN' ||
                            item.product.nftType === 'KLIP'
                          "
                        >
                          {{
                            memberInfo.klaytnAddr
                              ? memberInfo.klaytnAddr.slice(0, 10) +
                                "..." +
                                memberInfo.klaytnAddr.slice(-5)
                              : "(정보없음)"
                          }}
                        </template>
                      </template>
                    </p>
                    <template
                      v-if="
                        item.nftReceipt.klaytnScopeUrl !== undefined &&
                        item.nftReceipt.klaytnScopeUrl !== ''
                      "
                    >
                      <a :href="item.nftReceipt.klaytnScopeUrl" target="_blank">
                        <CIcon name="cil-external-link" />
                      </a>
                    </template>
                  </div>
                </div>
              </section>
            </article>
            <!-- 결제 정보 ========================================= -->
            <!-- <article class="content">
              <div class="title_box">{{ $t("mypage.nftTransaction") }}</div>
              <div class="info_box">
                <p>{{ $t("mypage.amount") }}</p>
                <p>
                  <template
                    v-if="
                      item.payType === 'VBANK' ||
                      item.payType === 'CARD' ||
                      item.payType === 'TRANSFER'
                    "
                  >
                    {{ item.amt | currency }} {{ item.currency }}
                  </template>
                  <template v-else>
                    {{ item.amountCoin | currency }} {{ item.currency }} ({{
                      item.amt | currency
                    }}
                    KRW)
                  </template>
                </p>
                <p>{{ $t("mypage.method") }}</p>
                <p>
                  <template v-if="item.payType === 'VBANK'">
                    {{ $t("mypage.payTypeVbank") }}
                  </template>
                  <template v-else-if="item.payType === 'CARD'">
                    {{ $t("mypage.payTypeCard") }}
                  </template>
                  <template v-else-if="item.payType === 'TRANSFER'">
                    {{ $t("mypage.payTypeTransfer") }}
                  </template>
                  <template v-else-if="item.payType === 'KLAY'">
                    {{ $t("mypage.payTypeKlay") }}
                  </template>
                  <template v-else-if="item.payType === 'MATIC'">
                    {{ $t("mypage.payTypeMATIC") }}
                  </template>
                  <template v-else-if="item.payType === 'SSX'">
                    {{ $t("mypage.payTypeSSX") }}
                  </template>
                  <template v-else-if="item.payType === 'ETH'">
                    {{ $t("mypage.payTypeEth") }}
                  </template>
                  ({{ replacePaymentStateName(item.stateCd) }})
                </p>
                <template v-if="item.payType === 'VBANK'">
                  <p>{{ $t("mypage.accountNumber") }}</p>
                  <p>
                    <span class="mr-2"
                      >{{ item.fnName }} {{ item.vbankNum }}</span
                    >
                    <template v-if="item.stateCd === '9'">
                      <v-icon small>mdi-alert</v-icon>
                      <span class="font-sm">
                        {{
                          (item.vbankExpDate + "T" + item.vbankExpTime)
                            | moment("YYYY년 MM월 DD일 HH시 mm분")
                        }}까지 미입금시 주문이 자동 취소됩니다.
                      </span>
                    </template>
                  </p>
                  <p>{{ $t("mypage.cashReceipt") }}</p>
                  <p>
                    <span v-html="getReceiptInfo(item)"></span>
                  </p>
                </template>
              </div>
            </article> -->
          </section>
        </b-collapse>
      </article>
    </template>

    <template v-if="paymentList.content.length === 0">
      <div class="col-12 mb-5 py-5 text-center" key="0">
        <p class="font-lg">{{ $t("mypage.noHistoryPayment") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const profileHelper = createNamespacedHelpers("profile");
const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentList",
  components: {},
  data() {
    return {
      externalParams: {
        keywordProductName: "",
        sort: ["orderDate,desc", "idx,desc"],
        size: 9999,
      },
      paymentStateOptions: [
        { value: "0", text: this.$t("mypage.statusComplete") },
        { value: "1", text: this.$t("mypage.statusCancelledBefore") },
        { value: "2", text: this.$t("mypage.statusCancelledAfter") },
        { value: "3", text: this.$t("mypage.statusReturned") },
        { value: "4", text: this.$t("mypage.statusFail") },
        { value: "9", text: this.$t("mypage.statusPending") },
        { value: "10", text: this.$t("mypage.statusProcessing") },
      ],
    };
  },
  computed: {
    ...profileHelper.mapState(["memberInfo"]),
    ...paymentHelper.mapState(["paymentList"]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {
    this.requestOrderPaymentItems();
  },
  methods: {
    ...paymentHelper.mapActions(["getPaymentList"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onClickSearch(keyword) {
      this.externalParams.keywordProductName = keyword;
      this.requestOrderPaymentItems();
    },
    onClickSortByItem(item) {
      this.externalParams.sort[0] = `${item.value.id},${item.value.desc}`;
      this.requestOrderPaymentItems();
    },
    requestOrderPaymentItems() {
      // @TODO Paging 추가 되어야할것같음
      this.getPaymentList(this.externalParams).then(() => {});
    },
    isProductFileTypeImage(_displayCardFileType) {
      if (
        _displayCardFileType !== undefined &&
        _displayCardFileType !== null &&
        _displayCardFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayCardUrl(product) {
      if (
        product.displayCardFile !== undefined &&
        product.displayCardFile !== null &&
        product.displayCardFile !== "" &&
        product.displayCardFile === "THREESPACE"
      ) {
        return product.displayCardUrlThreespace; // 3space S3 URL
      } else {
        if (
          product.displayCardUrl !== undefined &&
          product.displayCardUrl.startsWith("https://ipfs.io/")
        ) {
          return product.displayCardUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return product.displayCardUrl;
      }
    },
    replacePaymentStateName(stateCd) {
      if (stateCd !== undefined && stateCd !== null) {
        let state = this.paymentStateOptions.find((o) => o.value === stateCd);
        return state.text;
      } else {
        return "";
      }
    },
    getReceiptInfo(_item) {
      let receiptInfo = "(정보없음)";
      if (
        _item.receiptType !== undefined &&
        _item.receiptType !== null &&
        _item.receiptType !== ""
      ) {
        if (_item.receiptType === "0") {
          receiptInfo = this.$t("mypage.none");
        } else if (_item.receiptType === "1") {
          receiptInfo =
            this.$t("mypage.issued") +
            "(" +
            this.$t("mypage.personal") +
            ") " +
            _item.receiptTypeNo;
        } else if (_item.receiptType === "2") {
          receiptInfo =
            this.$t("mypage.issued") +
            "(" +
            this.$t("mypage.corporation") +
            ") " +
            _item.receiptTypeNo;
        }
      }
      return receiptInfo;
    },
    showProductDetail(idx) {
      this.$router.push("/art/" + idx);
    },
    getExpYear(vbankExpDate) {
      return vbankExpDate.substring(0, 4);
    },
    getExpMonth(vbankExpDate) {
      return vbankExpDate.substring(4, 6);
    },
    getExpDay(vbankExpDate) {
      return vbankExpDate.substring(6, 8);
    },
    getExpTime(vbankExpTime) {
      return `${vbankExpTime.substring(0, 2)}:${vbankExpTime.substring(2, 4)}`;
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    currencyCoin: function (value, currency) {
      if (currency === "ETH") {
        return parseFloat(Math.floor(value * 1000) / 1000)
          .toFixed(3)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
      }

      return parseInt(value)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.table-sm th,
.table-sm td {
  padding: 0.2rem;
}
</style>
